import { render, staticRenderFns } from "./NavigationDrawer.vue?vue&type=template&id=668976fd&scoped=true&"
import script from "./NavigationDrawer.vue?vue&type=script&lang=ts&"
export * from "./NavigationDrawer.vue?vue&type=script&lang=ts&"
import style0 from "./NavigationDrawer.vue?vue&type=style&index=0&id=668976fd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "668976fd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VDivider,VImg,VList,VListItem,VListItemTitle,VNavigationDrawer,VSpacer})
